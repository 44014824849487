var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/components/tracking/Calendar.tsx
import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { authUserAtom } from "../../../../atoms";
import { getData, putData } from "../../../../utils/api.util";
import Calendar from 'react-calendar';
import './react-calendar.css';
import BasicModal from "../../../../components/modal/modal";
import toast from "react-hot-toast";
// TODO: Fix this crappy hack later
var CustomDropdown = function (_a) {
    var items = _a.items, label = _a.label, onSelect = _a.onSelect;
    var _b = useState(null), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var handleChange = function (e) {
        var index = e.target.selectedIndex - 1; // Adjust for the default option
        setSelectedIndex(index);
        if (index >= 0) {
            onSelect(items[index]);
        }
    };
    return (_jsxs("div", { className: "custom-dropdown", children: [_jsx("label", { children: label }), _jsxs("select", { value: selectedIndex !== null ? selectedIndex + 1 : '', onChange: handleChange, children: [_jsx("option", { value: "", disabled: true, children: "Select an option" }), items.map(function (item, index) { return (_jsx("option", { value: index + 1, children: item.description }, item.id)); })] })] }));
};
export var CustomCalendar = function (_a) {
    var wins = _a.wins, setWins = _a.setWins, losses = _a.losses, setLosses = _a.setLosses, progress = _a.progress, setProgress = _a.setProgress, pointsGoal = _a.pointsGoal;
    var _b = useAtom(authUserAtom), user = _b[0], setUser = _b[1];
    var _c = useState(), data = _c[0], setData = _c[1];
    var _d = useState(new Date()), value = _d[0], onChange = _d[1];
    var _e = useState(false), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var _f = useState(null), selectedDate = _f[0], setSelectedDate = _f[1];
    var _g = useState({}), selectedItemsByDate = _g[0], setSelectedItemsByDate = _g[1];
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, initialSelectedItemsByDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getData(user.refreshToken, "/wins-losses/user/".concat(user.id), setData, "", setUser)];
                    case 1:
                        res = _a.sent();
                        setWins(res.data.wins);
                        setLosses(res.data.losses);
                        initialSelectedItemsByDate = {};
                        res.data.wins.forEach(function (win) {
                            var dateKey = new Date(win.updatedAt).toDateString();
                            if (!initialSelectedItemsByDate[dateKey]) {
                                initialSelectedItemsByDate[dateKey] = { wins: [], losses: [] };
                            }
                            initialSelectedItemsByDate[dateKey].wins.push(win);
                        });
                        res.data.losses.forEach(function (loss) {
                            var dateKey = new Date(loss.updatedAt).toDateString();
                            if (!initialSelectedItemsByDate[dateKey]) {
                                initialSelectedItemsByDate[dateKey] = { wins: [], losses: [] };
                            }
                            initialSelectedItemsByDate[dateKey].losses.push(loss);
                        });
                        setSelectedItemsByDate(initialSelectedItemsByDate);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [user.id]);
    var handleDayClick = function (date) {
        setSelectedDate(date);
        setIsModalOpen(true);
    };
    var handleWinLossSelect = function (item) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedItem, dateKey, endDate, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedItem = __assign(__assign({}, item), { updatedAt: selectedDate });
                    dateKey = (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.toDateString()) || '';
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    // Check before updating, that pointsGoal.endDate is after the selectedDate
                    if (!pointsGoal || !pointsGoal.endDate) {
                        toast.error("Points goal end date is not set");
                        return [2 /*return*/];
                    }
                    endDate = new Date(pointsGoal.endDate);
                    if (updatedItem.updatedAt > endDate) {
                        toast.error("Selected date is after points goal end date");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, putData(user.refreshToken, "/wins-losses/".concat(item.id), updatedItem, 'Item updated', setUser)];
                case 2:
                    _a.sent();
                    setProgress(progress + item.points);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    // TODO: Crappy hack to handle error, fix this
                    if (error_1.response.data.meta.cause.includes("not found")) {
                        toast.error("Create a points goal first!");
                    }
                    console.error("Failed to update item:", error_1);
                    // console.log(error.response.data.meta.cause);
                    return [2 /*return*/];
                case 4:
                    setSelectedItemsByDate(function (prevSelectedItemsByDate) {
                        var _a, _b;
                        var currentItems = prevSelectedItemsByDate[dateKey] || { wins: [], losses: [] };
                        if (item.type === "WIN") {
                            setWins(function (prevWins) { return prevWins.map(function (win) { return (win.id === item.id ? updatedItem : win); }); });
                            return __assign(__assign({}, prevSelectedItemsByDate), (_a = {}, _a[dateKey] = __assign(__assign({}, currentItems), { wins: __spreadArray(__spreadArray([], currentItems.wins, true), [updatedItem], false) }), _a));
                        }
                        else if (item.type === "LOSS") {
                            setLosses(function (prevLosses) { return prevLosses.map(function (loss) { return (loss.id === item.id ? updatedItem : loss); }); });
                            return __assign(__assign({}, prevSelectedItemsByDate), (_b = {}, _b[dateKey] = __assign(__assign({}, currentItems), { losses: __spreadArray(__spreadArray([], currentItems.losses, true), [updatedItem], false) }), _b));
                        }
                        return prevSelectedItemsByDate;
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var calculateTotalPoints = function (wins, losses) {
        var winPoints = wins.reduce(function (total, win) { return total + win.points; }, 0);
        var lossPoints = losses.reduce(function (total, loss) { return total + loss.points; }, 0);
        return winPoints - lossPoints;
    };
    var selectedItems = selectedDate ? selectedItemsByDate[selectedDate.toDateString()] || { wins: [], losses: [] } : { wins: [], losses: [] };
    return (_jsxs("div", { className: "calendar", children: [_jsx(Calendar, { onChange: onChange, value: value, onClickDay: handleDayClick, className: "react-calendar" }), _jsx(BasicModal, { title: "Select Win/Loss", content: _jsxs("div", { className: "win-loss-modal", children: [_jsxs("div", { className: "win-loss-container", children: [_jsxs("div", { className: "win-section", children: [_jsx("h3", { children: "Wins" }), _jsx(CustomDropdown, { items: wins, label: "Select Win", onSelect: handleWinLossSelect }), _jsx("div", { className: "selected-wins", children: selectedItems.wins.map(function (win) { return (_jsx("div", { className: "win-tag", children: win.description }, win.id)); }) })] }), _jsxs("div", { className: "loss-section", children: [_jsx("h3", { children: "Losses" }), _jsx(CustomDropdown, { items: losses, label: "Select Loss", onSelect: handleWinLossSelect }), _jsx("div", { className: "selected-losses", children: selectedItems.losses.map(function (loss) { return (_jsx("div", { className: "loss-tag", children: loss.description }, loss.id)); }) })] })] }), _jsxs("div", { className: "point-summation", children: [_jsxs("h3", { children: ["Total Points for ", selectedDate ? selectedDate.toDateString() : ''] }), _jsx("p", { children: calculateTotalPoints(selectedItems.wins, selectedItems.losses) })] })] }), open: isModalOpen, setOpen: setIsModalOpen })] }));
};
export default CustomCalendar;
