var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import React, { useState } from 'react';
import BasicFormControl from '../../../../components/inputForm/inputForm';
import { IconButton } from '../../../../components/iconButton/iconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import "./createPointsGoalForm.css";
import { authUserAtom } from '../../../../atoms';
import { useAtom } from 'jotai';
import { postData } from '../../../../utils/api.util';
import toast from 'react-hot-toast';
var customIconButtonStyle = {
    backgroundColor: "rgb(3, 27, 78)",
    margin: "20px 10px 10px 10px",
    border: "solid 1px black",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    width: "150px",
    color: "white",
    fontWeight: "bold",
};
var FORM_WIDTH = '95%'; // Adjust as needed
var CreatePointsGoalForm = function (_a) {
    var setProgress = _a.setProgress, setPointsGoal = _a.setPointsGoal, pointsGoal = _a.pointsGoal;
    var _b = useState(0), totalPoints = _b[0], setTotalPoints = _b[1];
    var _c = useState(''), endDate = _c[0], setEndDate = _c[1];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var _e = useAtom(authUserAtom), user = _e[0], setUser = _e[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedEndDate, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedEndDate = new Date(endDate).toISOString();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postData(user.refreshToken, "/points-goal", {
                            userId: user.id,
                            startDate: new Date(),
                            endDate: formattedEndDate,
                            totalPoints: Number(totalPoints),
                            currentPoints: 0,
                            createdAt: new Date(),
                            updatedAt: new Date()
                        }, "Created Points Goal", setUser)];
                case 2:
                    res = _a.sent();
                    setPointsGoal(res);
                    setTotalPoints(totalPoints);
                    setProgress(0);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    // If error response status = 409 then an active points goal already exists
                    if (error_1.response.status === 409) {
                        toast.error('An active points goal already exists');
                    }
                    console.error('Failed to create points goal:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClear = function () {
        setTotalPoints(0);
        setEndDate('');
    };
    return (_jsxs("div", { className: "create-skill-tree-container", children: [_jsx("p", { className: "edit-dropdown-label", children: "Total Points Goal" }), _jsx(BasicFormControl, { width: FORM_WIDTH, value: totalPoints, required: true, setUpdatedValue: function (value) { return setTotalPoints(value); } }), _jsx("p", { className: "edit-dropdown-label", children: "End Date" }), _jsx("input", { type: "date", value: endDate, onChange: function (e) { return setEndDate(e.target.value); }, className: "styled-input", required: true }), _jsx(Divider, { variant: "middle", style: { color: 'white', border: 'solid 0.1rem', paddingBottom: '10px' } }), _jsxs("div", { className: "create-skill-tree-buttons", style: { display: 'flex', gap: '20px', flexWrap: 'wrap' }, children: [_jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: function () { return handleSubmit(); }, content: 'Create', icon: _jsx(AddCircleOutlineIcon, {}) }), _jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: handleClear, content: 'Clear', icon: _jsx(BlockIcon, {}) })] })] }));
};
export default CreatePointsGoalForm;
