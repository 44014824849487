var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { postData } from "../../../../utils/api.util";
import { useAtom } from "jotai";
import { authUserAtom } from "../../../../atoms";
import "./winLossForm.css";
export var AddWinLossForm = function (_a) {
    var userId = _a.userId, type = _a.type, onSubmit = _a.onSubmit, wins = _a.wins, losses = _a.losses, setWins = _a.setWins, setLosses = _a.setLosses;
    var _b = useAtom(authUserAtom), user = _b[0], setUser = _b[1];
    var _c = useState(""), description = _c[0], setDescription = _c[1];
    var _d = useState(5), points = _d[0], setPoints = _d[1];
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newItem, response, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    newItem = { userId: userId, description: description, points: points, type: type, date: new Date(), updatedAt: new Date() };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(type === "WIN")) return [3 /*break*/, 3];
                    return [4 /*yield*/, postData(user.refreshToken, "/win", newItem, 'Item added', setUser)];
                case 2:
                    response = _a.sent();
                    setWins(__spreadArray(__spreadArray([], wins, true), [response], false));
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, postData(user.refreshToken, "/loss", newItem, 'Item added', setUser)];
                case 4:
                    response = _a.sent();
                    console.log(response);
                    setLosses(__spreadArray(__spreadArray([], losses, true), [response], false));
                    _a.label = 5;
                case 5:
                    onSubmit();
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _a.sent();
                    console.error("Failed to add item:", error_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("form", { className: "win-loss-form", onSubmit: handleSubmit, children: [_jsxs("h3", { children: ["Add ", type === "WIN" ? "Win" : "Loss"] }), _jsxs("div", { className: "form-group", children: [_jsx("label", { children: "Description" }), _jsx("input", { type: "text", value: description, onChange: function (e) { return setDescription(e.target.value); }, required: true })] }), _jsxs("div", { className: "form-group", children: [_jsx("label", { children: "Points" }), _jsx("input", { type: "number", value: points, onChange: function (e) { return setPoints(Number(e.target.value)); }, required: true })] }), _jsxs("button", { type: "submit", className: "custom-button", children: ["Add ", type === "WIN" ? "Win" : "Loss"] })] }));
};
