/**
 * Convert timestamp from: 2023-12-14T02:41:38.553Z
 * to: e.g. 15/06/2021
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { putData } from "./api.util";
import toast from "react-hot-toast";
import { useReducer } from "react";
export var convertTimestampToDate = function (timestamp) {
    var date = new Date(timestamp);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    // Convert from 24 hour time to 12 hour time
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    // Make sure 12 hour time is displayed correctly
    //
    return (day +
        "/" +
        month +
        "/" +
        year +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2) +
        " " +
        ampm);
};
export var colourizeAction = function (action) {
    switch (action) {
        case "POST":
            return "success";
        case "FINISHED":
            return "success";
        case "DELETE":
            return "error";
        case "FAILED":
            return "error";
        case "N/A":
            return "error";
        case "PUT":
            return "warning";
        case "QUEUED":
            return "warning";
        case "GET":
            return "primary";
        case "RUNNING":
            return "primary";
        default:
            return "default";
    }
};
export var transformData = function (data, type) {
    switch (type) {
        case "USER_HISTORY":
            return data.length > 0
                ? data === null || data === void 0 ? void 0 : data.map(function (historyItem) {
                    return {
                        action: historyItem.action,
                        time: convertTimestampToDate(historyItem.time),
                        name: historyItem.project ? historyItem.project.name : "N/A",
                    };
                })
                : [];
        case "JOBS":
            return data.length > 0
                ? data === null || data === void 0 ? void 0 : data.map(function (job) {
                    return {
                        // id: job.id,
                        description: job.description,
                        status: job.status,
                        searchTerm: job.searchTerm,
                        numPages: job.numPages,
                        downloadURL: job.downloadURL,
                        maxRetries: job.maxRetries,
                        createdAt: convertTimestampToDate(job.createdAt.toString()),
                        updatedAt: convertTimestampToDate(job.updatedAt.toString()),
                    };
                })
                : [];
        default:
            return data;
    }
};
export var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var typecastFormValue = function (value) {
    if (typeof value === "string") {
        return value;
    }
    else {
        return value;
    }
};
export var updatePhotoURL = function (user, base64photo, setUser) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, putData(user.refreshToken, "/user/".concat(user.id), { photo: base64photo, photoURL: user.photoURL }, "Successfully updated photo", setUser)
                    .then(function (res) {
                    setUser(res);
                })
                    .catch(function (err) {
                    toast.error(err.message);
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
// https://github.com/xrutayisire/react-js-cron/blob/master/src/stories/utils.stories.ts
// TODO: Fix this import
/**
 * Custom hook to update cron value and input value.
 *
 * Cannot use InputRef to update the value because of a change in antd 4.19.0.
 *
 * @param defaultValue - The default value of the input and cron component.
 * @returns - The cron and input values with the dispatch function.
 */
export function useCronReducer(defaultValue) {
    var _a = useReducer(function (prevValues, action) {
        switch (action.type) {
            case "set_cron_value":
                return {
                    inputValue: prevValues.inputValue,
                    cronValue: action.value,
                };
            case "set_input_value":
                return {
                    inputValue: action.value,
                    cronValue: prevValues.cronValue,
                };
            case "set_values":
                return {
                    inputValue: action.value,
                    cronValue: action.value,
                };
        }
    }, {
        inputValue: defaultValue,
        cronValue: defaultValue,
    }), values = _a[0], dispatchValues = _a[1];
    return [values, dispatchValues];
}
export var buildSkillTree = function (skills) {
    var skillMap = {};
    var roots = [];
    // Initialize skillMap and separate roots (skills without parentId)
    skills.forEach(function (skill) {
        skillMap[skill.id] = __assign(__assign({}, skill), { children: [] });
        if (!skill.parentId) {
            roots.push(skillMap[skill.id]);
        }
    });
    // Populate children
    skills.forEach(function (skill) {
        if (skill.parentId && skillMap[skill.parentId]) {
            skillMap[skill.parentId].children.push(skillMap[skill.id]);
        }
    });
    return skills;
    // Convert SkillWithChildren to SkillType
};
