var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabContext, TabPanel } from '@mui/lab';
var AntTabs = styled(Tabs)({
    // border: '1px solid rgb(3, 27, 78)',
    '& .MuiTabs-indicator': {
        backgroundColor: 'rgb(3, 27, 78)',
    },
});
var AntTab = styled(function (props) { return _jsx(Tab, __assign({ disableRipple: true }, props)); })(function (_a) {
    var theme = _a.theme;
    return ({
        textTransform: 'none',
        minWidth: 0,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        '&:hover': {
            color: 'rgb(3, 27, 78)',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: 'rgb(3, 27, 78)',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgb(3, 27, 78)',
        },
    });
});
export default function CustomizedTabs(_a) {
    var tabs = _a.tabs;
    var _b = React.useState(tabs.length > 0 ? tabs[0].label : ""), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (_jsx(Box, { component: "div", children: _jsxs(TabContext, { value: value, children: [_jsx(Box, { component: "div", sx: { borderBottom: 1, borderColor: 'divider' }, children: _jsx(AntTabs, { value: value, onChange: handleChange, children: tabs.map(function (tab, index) {
                            return _jsx(AntTab, { label: tab.label, value: tab.label }, index);
                        }) }) }), tabs.map(function (tab, index) {
                    return _jsx(TabPanel, { value: tab.label, children: _jsx("div", { children: tab.content }) }, index);
                })] }) }));
}
